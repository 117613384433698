import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data }) => (
  <Layout lang={"en-us"} globals={data.prismicGlobals}>
    <section className="container mx-auto px-8 mt-10   lg:px-32 lg:my-24 h-screen">
      <h1 className="font-sans text-4xl text-left font-extrabold mb-5 lg:text-6xl ">
        404: Not Found
      </h1>
    </section>
  </Layout>
)

export const query = graphql`
  query notFoundPageQuery {
    prismicGlobals(lang: { eq: "en-us" }) {
      data {
        follow_us
        read_more
        view_all
        footer_title
        footer_subtitle
        copyright
        store {
          link
          store_id
        }
        social {
          link
          social_id
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
